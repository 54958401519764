import React from 'react';

const CTA = ({ cta }) => (
	<section className="hero is-medium is-dark is-bold">
		<div className="hero-body">
			<div className="container">
				<div className="content has-text-centered">
					<h1 className="title">{cta && cta.title}</h1>
					<h2 className="subtitle">{cta && cta.text}</h2>
					<br />
					<a href={`https://${cta && cta.url}/`} className="button is-light is-large">
						<strong>Visit website</strong>
					</a>
				</div>
			</div>
		</div>
	</section>
);

export default CTA;
