import React from 'react';
import { Link } from 'gatsby';
import {
	Hero,
	Columns,
	Column,
	Container,
	Footer,
	Level,
	Left,
	Item,
	Box,
	Right,
	Menu,
	MenuItem
} from '../components/bulma';

import facebook from '../img/social/facebook.svg';
// import instagram from '../img/social/instagram.svg';
import twitter from '../img/social/twitter.svg';
import linkedin from '../img/social/linkedin.svg';

// import appStore from '../img/AppStore.svg';
// import googlePlay from '../img/GooglePlay.svg';
import finhub from '../img/finhub-logo.svg';

export default () => {
	return (
		<Footer>
			<Container>
				<div className="notification is-dark">
					<Columns>
						<Column>
							<Menu label="Finhub">
								<MenuItem to="/about">About</MenuItem>
								{/* <MenuItem to="/partners">Partners</MenuItem> */}
								<MenuItem to="/news">News</MenuItem>
								{/* <MenuItem to="/speakers">Speakers</MenuItem> */}
								{/* <MenuItem to="/media">Media</MenuItem> */}
								<MenuItem to="/contact">Contact</MenuItem>
							</Menu>
						</Column>
						<Column>
							<Menu label="portfolio">
								<MenuItem to="/portfolio/cardql">CardQL</MenuItem>
								<MenuItem to="/portfolio/cent">Cent</MenuItem>
								<MenuItem to="/portfolio/mark">Mark</MenuItem>
								<MenuItem to="/portfolio/payment-ninja">Payment.Ninja</MenuItem>
								<MenuItem to="/portfolio/ruby">Ruby</MenuItem>
								<MenuItem to="/portfolio/unmerchant">Unmerchant</MenuItem>
							</Menu>
						</Column>
						{/* <Column>
								<Menu label="Services">
									<MenuItem to="/services/rapid-prototyping">Rapid Prototyping</MenuItem>
									<MenuItem to="/services/due-diligence">Due Diligence</MenuItem>
									<MenuItem to="/services/technology-consulting">Technology Consulting</MenuItem>
									<MenuItem to="/services/compliance-solutions">Compliance Solutions</MenuItem>
									<MenuItem to="/services/white-label-software">White-Label Software</MenuItem>
								</Menu>
							</Column> */}

						{/* <Column>
								<Menu label="Verticals">
									<MenuItem to="/verticals/payments">Payments</MenuItem>
									<MenuItem to="/verticals/money-remittance">Money Remittance</MenuItem>
									<MenuItem to="/verticals/mobile-wallets">Mobile Wallets</MenuItem>
									<MenuItem to="/verticals/cloud-serivces">Cloud Services</MenuItem>
									<MenuItem to="/verticals/data-api">Data and APIs</MenuItem>
								</Menu>
							</Column> */}

						<Column>
							<Menu label="Resources">
								{/* <MenuItem to="/developers">Developers</MenuItem> */}
								{/* <MenuItem to="/blog">Blog</MenuItem> */}
								<MenuItem to="/fintech">FinTech Digest</MenuItem>
								<MenuItem to="/videos">Videos</MenuItem>
								{/* <MenuItem to="/apps">Download Apps</MenuItem> */}
								{/* <MenuItem to="/events-calendar">Events Calendar</MenuItem> */}
								{/* <MenuItem to="/faq">FAQ</MenuItem> */}
							</Menu>
						</Column>
					</Columns>
				</div>

				<br />
				<Level>
					<Left>
						<p>
							<a title="facebook" href="https://www.facebook.com/FinHub.vc/">
								<img
									src={facebook}
									alt="Facebook"
									style={{ width: '2em', height: '2em', marginRight: 15 }}
								/>
							</a>
							<a title="twitter" href="https://twitter.com/FinHub_vc">
								<img
									src={twitter}
									alt="Twitter"
									style={{ width: '2em', height: '2em', marginRight: 15 }}
								/>
							</a>
							<a title="linkedin" href="https://www.linkedin.com/company/finhub.global/">
								<img
									src={linkedin}
									alt="Twitter"
									style={{ width: '2em', height: '2em', marginRight: 15 }}
								/>
							</a>
							{/* <a title="instagram" href="https://www.instagram.com/cashmark_app/">
								<img src={instagram} alt="Instagram" style={{ width: '2em', height: '2em' }} />
							</a> */}
						</p>
					</Left>
					<Right>
						<Item>
							<div id="navMenu" className={`navbar-menu`}>
								<div className="navbar-end has-text-centered is-size-7">
									<Link className="navbar-item" to="/terms">
										Terms of service
									</Link>
									<Link className="navbar-item" to="/privacy">
										Privacy
									</Link>

									<Link className="navbar-item" to="/contact">
										Contact
									</Link>
									<Link className="navbar-item" to="/faq">
										FAQ
									</Link>
								</div>
							</div>
						</Item>
					</Right>
				</Level>

				<Level>
					<Left>
						<p className="has-text-left is-size-7">
							<strong>© 2020 FinHub Global Ventures</strong>. All rights reserved.
							<br />All product names, trademarks and registered trademarks are property of their
							respective owners.
							<br />All company, product and service names used in this website are for identification
							purposes only.
							<br />Use of these names, trademarks and brands does not imply endorsement.
							<br />Mobile apps by{' '}
							<a href="https://fastlabs.co/" target="_blanc">
								Fastlabs
							</a>. Website by{' '}
							<a href="https://superfast.page/" target="_blanc">
								Superfast
							</a>.
						</p>
					</Left>
					<Right>
						<div
							style={{
								width: '120px',
								display: 'inline-block'
							}}
						>
							<Link to="/" title="FinHub Global Ventures">
								<img src={finhub} alt="FinHub Global Ventures logo" />
							</Link>
						</div>
					</Right>
				</Level>
				<br />
			</Container>
		</Footer>
	);
};
